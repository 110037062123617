import type { AdDetails } from '../../@types/adCommon';
import { isEmptyObject } from '../components/util';
import { prepareCreativeTemplateNameForTagging } from './render';
import { addJSONQueryParamToURL } from '@amzn/apejs-url-query-params';
import { ViewablePixelTrace, buildPixelUrl, firePixel } from '../components/pixel';
import { GLOBAL_IMPRESSION_FIRED_COUNTER } from '../components/counters/IMPRESSION_COUNTERS';
import { IMPRESSION as BTRIMPRESSION } from '../components/counters/BTR_COUNTERS';
import { IMPRESSION as CODIMPRESSION } from '../components/counters/COD_COUNTERS';
import {
    IMP,
    MEASURABILITY,
    UNSERVED_IMP,
    UNSERVED_VIEWABILITY,
    VIEWABILITY,
} from '../components/counters/AD_LOAD_COUNTERS';
import * as AD_LOAD_EVENTS from '../components/events/AD_LOAD_EVENTS';
import type { ViewabilityStandard, ViewableInfo } from '@amzn/safe-frame-client-amzn';
import { ViewableTimeCalculator } from './viewableTimeCalculator';
import { evaluateBTRCriteriaSync } from '@amzn/creative-rendering-monitor';
import { BEGIN_TO_RENDER_CLIENT, COUNT_ON_DOWNLOAD_CLIENT, MEASUREMENT_METHODS } from './MEASUREMENT_METHODS';
import { ClientApis } from './types/types';
import { isImageTemplate } from '../components/templates';
import { PUBLISHER_JSON_KEY } from '../components/aax/aax';
import { ADPT_SF_BTR_PIXEL_997225 } from '../components/weblabs';
import { logError } from '@amzn/apejs-instrumentation/src/metrics/logging';

type ViewedPixel = {
    viewed?: boolean;
    timeout?: number | null;
};
export type ViewableTimeCalculators = { [key: string]: ViewableTimeCalculator };

// Responsible for reporting impressions, views and measurability.
export class ClientReporter {
    constructor(
        readonly c: ClientApis,
        readonly o: AdDetails,
    ) {}

    // Viewability flags
    // TODO (Is this split type on timeout actually correct???)
    private readonly viewedPixels: { [key: string]: ViewedPixel } = {};
    /**
     * Tracks the amount of time a creative been considered viewable by viewability definitions.
     * Each key is a viewability definition - e.g. 'iab'
     */
    public readonly _viewableTimeCalculators: ViewableTimeCalculators = {};

    private hasLoaded = false;
    fireImageLoaded = (creativeTemplateName: string) => {
        if (!this.hasLoaded && isImageTemplate(creativeTemplateName)) {
            const adImage = document.getElementsByClassName('ad-background-image')?.[0];
            if (adImage && adImage.tagName === 'IMG') {
                if ((adImage as HTMLImageElement).complete) {
                    this.c.countMetric('imageLoaded', 1, true);
                    this.c.countMetric('imageLoaded:adid:' + this.o.adCreativeMetaData.adId, 1, true);
                    this.hasLoaded = true;
                } else {
                    const prevOnLoad = (adImage as HTMLImageElement).onload?.bind(window);
                    (adImage as HTMLImageElement).onload = (event: Event) => {
                        if (prevOnLoad) {
                            prevOnLoad(event);
                        }
                        this.c.countMetric('imageLoaded', 1, true);
                        this.c.countMetric('imageLoaded:adid:' + this.o.adCreativeMetaData.adId, 1, true);
                        this.hasLoaded = true;
                    };
                }
            }
        }
    };

    fireImpressionPixel = async (measurementMethod: MEASUREMENT_METHODS, isNoInventory?: boolean) => {
        if (isNoInventory) {
            await firePixel(this.c, {
                id: 'ape_ni_impression',
                pixelUrl: buildPixelUrl(this.o.aaxInstrPixelUrl, 'nii/', { ni: true }),
                baseCounterName: UNSERVED_IMP,
                baseMetricName: AD_LOAD_EVENTS.UNSERVED_IMP,
            });
        }
        let isPixelFired = false;
        const isBtrPixel = measurementMethod === BEGIN_TO_RENDER_CLIENT;
        if (ADPT_SF_BTR_PIXEL_997225().isT1()) {
            if (isBtrPixel) {
                // TODO(https://issues.amazon.com/issues/AXPFR-4690) remove when DRA supports BTR pixel.
                const ADSP = '1002';
                const CLASS1 = '1004';
                // ADSP uses 0 to force preview in testing.
                const ADSP_FORCE_PREVIEW = '0';
                const btrReadyPrograms = [ADSP, CLASS1, ADSP_FORCE_PREVIEW];
                if (btrReadyPrograms.includes(this.o.adCreativeMetaData.adProgramId)) {
                    if (!this.o.btrPixelUrl) {
                        logError('No btrPixelUrl for btr eligible creative');
                        return;
                    }
                    isPixelFired = await firePixel(this.c, {
                        id: 'ape_btr_impression',
                        pixelUrl: createPixelUrl(
                            removeAAXPayloadFromUrl(this.o.btrPixelUrl),
                            'c',
                            BEGIN_TO_RENDER_CLIENT,
                        ),
                        baseCounterName: IMP,
                        baseMetricName: AD_LOAD_EVENTS.IMP,
                    });
                }
            } else {
                isPixelFired = await firePixel(this.c, {
                    id: 'ape_impression',
                    pixelUrl: createPixelUrl(this.o.aaxImpPixelUrl, PUBLISHER_JSON_KEY, COUNT_ON_DOWNLOAD_CLIENT),
                    baseCounterName: IMP,
                    baseMetricName: AD_LOAD_EVENTS.IMP,
                });
            }
        } else {
            isPixelFired = await firePixel(this.c, {
                id: 'ape_impression',
                pixelUrl: createPixelUrl(this.o.aaxImpPixelUrl, PUBLISHER_JSON_KEY, measurementMethod),
                baseCounterName: IMP,
                baseMetricName: AD_LOAD_EVENTS.IMP,
            });
        }

        if (isPixelFired) {
            this.emitImpressionPixelMetrics(isBtrPixel);
        }
    };

    fireMeasurabilityPixel = (data: { atf: string | boolean }) => {
        if (isEmptyObject(this.o) || isEmptyObject(this.c) || !this.o.aaxInstrPixelUrl) {
            return;
        }
        const pixelId = 'ape_measurability';
        if (this.viewedPixels[pixelId]) {
            return;
        }
        firePixel(this.c, {
            id: pixelId,
            pixelUrl: buildPixelUrl(this.o.aaxInstrPixelUrl, 'atf/', {
                atf: data.atf,
            }),
            baseCounterName: MEASURABILITY,
            baseMetricName: AD_LOAD_EVENTS.MEASURABILITY,
        });
        this.viewedPixels[pixelId] = {};
    };

    /**
     * @desc [Internal] Checks if viewable info for a video creative meets viewability standards,
     *   and if so fires viewability pixels
     * @param cachedViewability - The percentage of a creative that is currently in the viewport
     * @param viewableInfo
     * @param viewabilityStandards - Each standard must be met before its
     *   corresponding viewability pixel can be fired
     * @param isNoInventory - Indicates whether any ad was returned or not
     * @param playingTimeInSeconds - video playing time without user-initiated interruptions
     */
    fireViewablePixelsForVideo = (
        cachedViewability: number | null,
        viewableInfo: ViewableInfo,
        viewabilityStandards: ViewabilityStandard[],
        isNoInventory: boolean,
        playingTimeInSeconds: number | null,
    ) => {
        if (isEmptyObject(this.o) || isEmptyObject(this.c) || !this.o.aaxInstrPixelUrl || !viewabilityStandards) {
            return;
        }
        const meetsViewabilityStandard = (
            viewableTime: number,
            playingTime: number | null,
            viewabilityStandard: ViewabilityStandard,
        ) => {
            const meetsViewableTimeRequirement = viewableTime >= viewabilityStandard.t;
            const meetsPlayingTimeRequirement = playingTime && playingTime >= viewabilityStandard.t;
            return meetsViewableTimeRequirement && meetsPlayingTimeRequirement;
        };
        const fireViewablePixel = (v: ViewabilityStandard) => {
            viewableInfo.v = v;
            const ps = this.o.computed?.aPageStart;
            const as = this.o.computed?.adStartTime;
            viewableInfo.ptv = ps ? (Date.now() - ps) / 1000 : 0;
            viewableInfo.ttv = as ? (Date.now() - as) / 1000 : 0;
            if (isNoInventory) {
                viewableInfo.niv = true;
            }
            const viewablePixelTrace: ViewablePixelTrace = {
                viewableInfo: viewableInfo,
                aaxInstrPixelUrl: this.o.aaxInstrPixelUrl,
                viewablePercentage: cachedViewability,
            };
            let pixelUrl;
            if (!isNoInventory) {
                pixelUrl = buildPixelUrl(this.o.aaxInstrPixelUrl, 'v/', viewableInfo);
                firePixel(
                    this.c,
                    {
                        id: 'ape_viewability' + '_' + v.def,
                        pixelUrl,
                        baseCounterName: VIEWABILITY,
                        baseMetricName: AD_LOAD_EVENTS.VIEWABILITY,
                        counterExtension: v.def,
                    },
                    viewablePixelTrace,
                );
            } else {
                pixelUrl = buildPixelUrl(this.o.aaxInstrPixelUrl, 'niv/', viewableInfo);
                firePixel(
                    this.c,
                    {
                        id: 'ape_ni_viewability' + '_' + v.def,
                        pixelUrl,
                        baseCounterName: UNSERVED_VIEWABILITY,
                        baseMetricName: AD_LOAD_EVENTS.UNSERVED_VIEWABILITY,
                        counterExtension: v.def,
                    },
                    viewablePixelTrace,
                );
            }
        };
        const measureViewability = (viewabilityStandard: ViewabilityStandard, viewablePercentage: number | null) => {
            const def = viewabilityStandard.def;
            if (viewablePercentage && isViewable(viewablePercentage, viewabilityStandard.p)) {
                this._viewableTimeCalculators[def].transitionToViewableState(Date.now());
            } else {
                this._viewableTimeCalculators[def].transitionToNotViewableState();
            }
            const viewableTimeInSeconds = this._viewableTimeCalculators[def].getViewableTimeInSeconds(Date.now());
            const shouldFire =
                !this.viewedPixels[def].viewed &&
                meetsViewabilityStandard(viewableTimeInSeconds, playingTimeInSeconds, viewabilityStandard);
            if (shouldFire) {
                this.viewedPixels[def].viewed = true;
                fireViewablePixel(viewabilityStandard);
            }
        };
        for (let i = 0; i < viewabilityStandards.length; i++) {
            const def = viewabilityStandards[i].def;
            if (def) {
                this.viewedPixels[def] = this.viewedPixels[def] || {};
                this.viewedPixels[def].viewed =
                    typeof this.viewedPixels[def].viewed === 'undefined' ? false : this.viewedPixels[def].viewed;
                if (typeof this._viewableTimeCalculators[def] === 'undefined') {
                    this._viewableTimeCalculators[def] = new ViewableTimeCalculator();
                }
            }
        }
        // Fire pixels following this standard: https://w.amazon.com/index.php/A9/AAX/Specifications/AAXLogFormatV2_0#Pixel_View_Payload_Log
        for (let i = 0; i < viewabilityStandards.length; i++) {
            measureViewability(viewabilityStandards[i], cachedViewability);
        }
    };

    /**
     * @method fireViewablePixels
     * @desc [Internal] Checks if viewable info for a non-video creative meets viewability standards,
     *   and if so fires viewability pixels
     * @param {Number} cachedViewability - The percentage of a creative that is currently in the viewport
     * @param {Object} viewableInfo
     * @param {Array.<ViewabilityStandard>} viewabilityStandards - Each standard must be met before its
     *   corresponding viewability pixel can be fired
     * @param {Boolean} isNoInventory - Indicates whether any ad was returned or not
     * @param {Date} renderCompleteTime - The date to use for render time completion. Use this to calculate
     */
    fireViewablePixels = (
        cachedViewability: number | null,
        viewableInfo: ViewableInfo,
        viewabilityStandards: ViewabilityStandard[],
        isNoInventory: boolean,
    ) => {
        const fireViewablePixel = (v: ViewabilityStandard) => {
            viewableInfo.v = v;
            const ps = this.o.computed?.aPageStart;
            const as = this.o.computed?.adStartTime;
            viewableInfo.ptv = ps ? (Date.now() - ps) / 1000 : 0;
            viewableInfo.ttv = as ? (Date.now() - as) / 1000 : 0;
            if (isNoInventory) {
                viewableInfo.niv = true;
            }
            const viewablePixelTrace: ViewablePixelTrace = {
                viewableInfo: viewableInfo,
                aaxInstrPixelUrl: this.o.aaxInstrPixelUrl,
                viewablePercentage: cachedViewability,
            };
            let pixelUrl;
            if (!isNoInventory) {
                pixelUrl = buildPixelUrl(this.o.aaxInstrPixelUrl, 'v/', viewableInfo);
                firePixel(
                    this.c,
                    {
                        id: 'ape_viewability' + '_' + v.def,
                        pixelUrl,
                        baseCounterName: VIEWABILITY,
                        baseMetricName: AD_LOAD_EVENTS.VIEWABILITY,
                        counterExtension: v.def,
                    },
                    viewablePixelTrace,
                );
            } else {
                pixelUrl = buildPixelUrl(this.o.aaxInstrPixelUrl, 'niv/', viewableInfo);
                firePixel(
                    this.c,
                    {
                        id: 'ape_ni_viewability' + '_' + v.def,
                        pixelUrl,
                        baseCounterName: UNSERVED_VIEWABILITY,
                        baseMetricName: AD_LOAD_EVENTS.UNSERVED_VIEWABILITY,
                        counterExtension: v.def,
                    },
                    viewablePixelTrace,
                );
            }
        };
        const measureViewability = (viewabilityStandard: ViewabilityStandard) => {
            const p = viewabilityStandard.p;
            const t = viewabilityStandard.t;
            const def = viewabilityStandard.def;
            if (!this.viewedPixels[def].viewed && cachedViewability && isViewable(cachedViewability, p)) {
                if (!this.viewedPixels[def].timeout) {
                    this.viewedPixels[def].timeout = (setTimeout as typeof window.setTimeout)(() => {
                        this.viewedPixels[def].viewed = true;
                        fireViewablePixel(viewabilityStandard);
                    }, 1000 * t);
                }
            } else if (this.viewedPixels[def].timeout) {
                (clearTimeout as typeof window.clearTimeout)(this.viewedPixels[def].timeout as number);
                this.viewedPixels[def].timeout = null;
            }
        };
        // Fire pixels following this standard: https://w.amazon.com/index.php/A9/AAX/Specifications/AAXLogFormatV2_0#Pixel_View_Payload_Log
        for (const vs of viewabilityStandards) {
            const def = vs.def;
            if (!this.viewedPixels?.[def]) {
                this.viewedPixels[def] = { viewed: false, timeout: null };
            }
            measureViewability(vs);
        }
    };

    private emitImpressionPixelMetrics(isBtrPixel: boolean) {
        const adProgramId = this.o.adCreativeMetaData.adProgramId ? this.o.adCreativeMetaData.adProgramId : 'unknown';
        const creativeTemplateName = this.o.adCreativeMetaData.adCreativeTemplateName;
        const creativeTemplateNameForMetrics = prepareCreativeTemplateNameForTagging(creativeTemplateName);
        this.c.countMetric(GLOBAL_IMPRESSION_FIRED_COUNTER, 1, true);
        this.c.countMetric(GLOBAL_IMPRESSION_FIRED_COUNTER + ':program:' + adProgramId, 1, true);
        this.c.countMetric(GLOBAL_IMPRESSION_FIRED_COUNTER + ':template:' + creativeTemplateNameForMetrics, 1, true);
        if (ADPT_SF_BTR_PIXEL_997225().isT1()) {
            const impressionMetric = isBtrPixel ? BTRIMPRESSION : CODIMPRESSION;
            this.c.countMetric(impressionMetric, 1, false);
            this.c.countMetric(impressionMetric, 1, true);
            this.c.countMetric(impressionMetric + ':program:' + adProgramId, 1, true);
            this.c.countMetric(impressionMetric + ':template:' + creativeTemplateNameForMetrics, 1, true);
        } else {
            if (isBtrPixel) {
                this.c.countMetric(BTRIMPRESSION, 1, false);
                this.c.countMetric(BTRIMPRESSION, 1, true);
                this.c.countMetric(BTRIMPRESSION + ':program:' + adProgramId, 1, true);
                this.c.countMetric(BTRIMPRESSION + ':template:' + creativeTemplateNameForMetrics, 1, true);
            }
        }
    }
}

// Helper method to check if ad is viewable or not and handle the edge case when p equals 0
const isViewable = (viewablePercentage: number, p: number) => {
    return (100 * viewablePercentage >= p && p !== 0) || (100 * viewablePercentage > p && p === 0);
};

// Creates the filled out impression tracking URL
export const createPixelUrl = (
    aaxImpPixelUrl: string,
    queryParamKey: string,
    measurementMethod: MEASUREMENT_METHODS,
) => {
    return addJSONQueryParamToURL(aaxImpPixelUrl, queryParamKey, {
        measurementMethod: measurementMethod,
    });
};

const removeAAXPayloadFromUrl = (url: string): string => {
    const regex = /&c=\${AAX_PAYLOAD}/;
    return url.replace(regex, '');
};

/**
 * Evaluate impression measurement method.
 *
 * Since we do not clearly know when necessary creative images are actually available in DOM, wait until iframe has loaded to perform this check.
 *
 * Given that we assure firing of impression after creative html is available, the default measurementMethod is Count on Download.
 * If we are additionally able to validate that images in ad creative have successfully loaded, set measurementMethod to Begin To Render.
 * Assumes resources have been loaded but there may be dynamic still here
 */
const evaluateImpressionMeasurementMethodInternal = (): MEASUREMENT_METHODS => {
    const btrCriteriaResponse = evaluateBTRCriteriaSync(document, document.body);
    if (btrCriteriaResponse.isBTRCompliant) {
        return 'btr_client';
    } else {
        return 'cod_client';
    }
};

// We have the problem that we don't have a way to cleanly identify BTR
export const isLikelyBTRCreative = (html: string): boolean => {
    return html.includes('mrc-btr-creative');
};

/**
 * @param originalHtml The raw html source that we will scan to determine if it is a BTR document
 */
export const evaluateImpressionMeasurementMethod = async (originalHtml: string): Promise<MEASUREMENT_METHODS> => {
    if (!isLikelyBTRCreative(originalHtml)) {
        return Promise.resolve(evaluateImpressionMeasurementMethodInternal());
    } else {
        if (evaluateImpressionMeasurementMethodInternal() === 'btr_client') return Promise.resolve('btr_client');
        const imagesLoaded = new Promise<void>((r) => {
            const mo = new MutationObserver((mutations, sourceMo) => {
                for (const mutation of mutations) {
                    const image = mutation.target as HTMLImageElement;
                    if (image?.complete && image?.naturalHeight > 0 && image?.naturalWidth > 0) {
                        sourceMo.disconnect();
                        r();
                    }
                }
            });
            mo.observe(document.body, {
                subtree: true,
                childList: true,
                attributeFilter: ['complete', 'naturalWidth', 'naturalHeight'],
            });
            // For now we just call timeout 75ms to give a chance for the event loop on creative dom be processed
            // TODO Move to the BTR pixel so we can decouple this
            setTimeout(() => {
                r();
                mo.disconnect();
            }, 75);
        });
        await imagesLoaded;
        return Promise.resolve(evaluateImpressionMeasurementMethodInternal());
    }
};
